import React, {useCallback, useEffect, useState} from 'react';
import {useAuth} from '../util/auth';
import {useLocalStorage} from '../util/hooks/useLocalStorage';
import {useRouter} from 'next/router';
import Link from 'next/link';
import {fetchSelfAssessment} from '../util/assessment';
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger
} from '@/components/ui/dialog';
import {Button} from '@/components/ui/button';
import {Typography} from '@/components/ui/typography';
import {Separator} from '@/components/ui/separator';

const useSelfAssessmentStorage = () => {
    const [notifiedTimestamp, setNotifiedTimestamp] = useLocalStorage(`self-assessment-notified`, null);

    return [notifiedTimestamp, setNotifiedTimestamp];
}

const useShouldShowModal = (notifiedTimestamp: string) => {
    const [shouldShow, setShouldShow] = useState(false);
    const auth = useAuth();
    const parsedTs = parseInt(notifiedTimestamp, 10);
    const hasBeenNotified = parsedTs ? (Date.now() - parsedTs) < (2 * 24 * 60 * 60 * 1000)  : null;

    useEffect(() => {
        if (auth.isAuthenticated) {
            fetchSelfAssessment().then((response) => {
                const alreadyCompletedAssessment = Object.keys(response || {}).length > 0;
                setShouldShow(!alreadyCompletedAssessment);
            })
        }
    }, [auth.isAuthenticated])

    if (!auth.isAuthenticated || hasBeenNotified) {
        return false;
    }

    return shouldShow;
}

export const SelfAssessmentModal = () => {
    const router = useRouter();
    const [notifiedTimestamp, setNotifiedTimestamp] = useSelfAssessmentStorage();
    const updateNotificationTime = useCallback(() => {
        setNotifiedTimestamp(Date.now())
    }, []);

    const shouldShow = useShouldShowModal(notifiedTimestamp);

    if (!shouldShow) {
        return null;
    }

    return (
        <Dialog open={shouldShow} onOpenChange={() => {
            updateNotificationTime();
        }}>
            <DialogContent className="sm:max-w-[425px] bg-backgroundBrand">
                <DialogHeader>
                    <DialogTitle>Take your leadership assessment now!</DialogTitle>
                </DialogHeader>
                <div className="grid gap-4 py-4">
                    <Typography>
                        It takes 15 minutes to complete.
                        Once submitted, you will immediately
                        receive your results and a leadership
                        excellence score.
                    </Typography>
                    <Typography>
                        Then you'll be ready to create your
                        personal training programme
                    </Typography>
                </div>
                <DialogFooter>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            updateNotificationTime();
                        }}

                    >
                        Remind me later
                    </Button>
                    <Button
                        onClick={() => {
                            updateNotificationTime();
                            router.push('/self-assessment')
                        }}

                    >Start here</Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}
