import React, {useState} from 'react';
import Link from 'next/link';
import {UserAvatar} from '@/components/UserAvatar';
import {useAuth} from '@/util/auth';
import {useSelector} from 'react-redux';
import {selectUserById} from '@/features/usersSlice';
import {Typography} from '@/components/ui/typography';
import {
    CaseCard as Card,
    CaseCardActions,
    CaseCardContent,
    CaseCardDescription, CaseCardFooter,
    CaseCardHeader,
    CaseCardIcons
} from '@/components/ui/case-card';
import {Badge} from '@/components/ui/badge';
import {Separator} from '@/components/ui/separator';
import {cn} from '@/lib/utils';
import {selectCaseById} from '@/features/casesSlice';
import {Button} from '@/components/ui/button';
import {ArrowRight, CalendarClock, Clock4, MessageCircle, Users, Bookmark, Heart} from 'lucide-react';
import {formatDate, formatName} from '@/util/format';
import {selectTimezone} from '@/features/timezoneSlice';
import {CaseStatuses, DEFAULT_SESSION_DURATION_IN_MINUTES} from '@/constants';
import {useStableCallback} from '@/util/hooks/useStableCallback';

type CaseCardProps = {
    caseId: string;
}

const CaseCard = ({caseId}: CaseCardProps) => {
    const currentCase = useSelector((state) => selectCaseById(state, caseId));
    const userId = currentCase?.user;
    const user = useSelector(state => userId ? selectUserById(state, userId) : null);
    const timezone = useSelector(selectTimezone);
    const [isLoginOverlayVisible, setIsLoginOverlayVisible] = useState(false);
    const auth = useAuth();
    const isOpenCase = ((!auth.isAuthenticated && currentCase?.public) || auth.isAuthenticated);

    const onFocus = useStableCallback(() => {
        if (!isOpenCase) {
            setIsLoginOverlayVisible(true);
        }
    });

    const onBlur = useStableCallback(() => {
        setIsLoginOverlayVisible(false);
    });

    if (!currentCase) {
        return null;
    }

    //convert expertise to set to remove duplicates
    const expertises = new Set(currentCase.expertises?.map(expertise => expertise.title));
    const isPublic = currentCase.public;
    const isUpcoming = currentCase.scheduled_to > (Date.now() / 1000);
    const description = currentCase.excerpt;
    const title = currentCase.title;
    const state = currentCase?.state;
    const isPlaceholder = state === CaseStatuses.TENTATIVE;
    const isClosed = state === CaseStatuses.CLOSED;

    return (
        <Card className="flex flex-col hover:bg-teal-50">
            <CaseCardHeader className="px-6 pt-6">
                <div className="flex items-center justify-between gap-2">
                    <div className="flex flex-wrap gap-1">
                        {isPublic ? (
                            <Badge variant="secondary" className="bg-teal-200">Free</Badge>
                        ) : null}
                    </div>
                </div>

                {isPlaceholder ? (
                    <Typography className="decoration-0 font-semibold text-lg line-clamp-2" variant="large">
                        {title}
                    </Typography>
                ) : (
                    <Link href={`/case/${caseId}`} passHref>
                        <Typography className="decoration-0 font-semibold text-lg line-clamp-2" variant="large" asChild>
                            <a>{title}</a>
                        </Typography>
                    </Link>
                )}
            </CaseCardHeader>
            <CaseCardContent className="px-6 gap-4 h-full flex flex-col justify-between">
                <div className="flex flex-col gap-4">
                    {!!description && (
                        <CaseCardDescription>
                            <div className={cn("animate flex items-center absolute inset-0 pointer-events-none bg-teal-50 backdrop-blur-2xl", {
                                'opacity-0': !isLoginOverlayVisible,
                            })}>
                                <p>Become a Casehub member to see more. <strong>Free trial for 14 days</strong>, cancel any time.</p>
                            </div>
                            <Typography variant="default" className="line-clamp-6">
                                {description}
                            </Typography>
                        </CaseCardDescription>
                    )}

                    <div className="flex flex-wrap gap-1 text-muted-foreground text-xs">
                        {Array.from(expertises)?.map((exp) => (
                            <span key={exp}>#{exp}</span>
                        ))}
                    </div>

                    <CaseCardIcons>
                        {
                            (!isUpcoming || (currentCase.comment_count > 0)) && (
                                <Button variant="secondary" size="subtle">
                                    <MessageCircle className="w-4 h-4" />
                                    {currentCase.comment_count}
                                </Button>
                            )
                        }
                        {(isUpcoming || currentCase.register_count > 0) && (
                            <>
                                <Button variant="secondary" size="subtle">
                                    <Users className="w-4 h-4" />
                                    {currentCase.register_count}/{currentCase.register_max}
                                </Button>
                            </>
                        )}
                    </CaseCardIcons>

                    {isUpcoming && (
                        <div className="flex gap-4">
                            <div className="flex gap-x-2 text-xs text-muted-foreground items-center">
                                <CalendarClock className="w-4 h-4" /> {formatDate({ts: currentCase.scheduled_to, format: 'medium', tz: timezone})}
                            </div>
                            <div className="flex gap-x-2 text-xs text-muted-foreground items-center">
                                <Clock4 className="w-4 h-4" /> {currentCase.duration || DEFAULT_SESSION_DURATION_IN_MINUTES} min
                            </div>
                        </div>
                    )}

                    <Separator className="mb-2"/>
                </div>

                <CaseCardActions>
                    <div onMouseEnter={onFocus} onMouseLeave={onBlur}>
                        <Button
                            asChild
                            variant="outline"
                            environment="light"
                            size="lg"
                        >
                            <a href={isLoginOverlayVisible ? `/pricing` : `/case/${currentCase.id}`}>
                                {isLoginOverlayVisible ? 'Become a member' : 'Enter case'}
                                <ArrowRight className="w-4 h-4" />
                            </a>
                        </Button>
                    </div>
                </CaseCardActions>
            </CaseCardContent>
            <CaseCardFooter className={cn("flex px-6 py-2 items-center space-x-4", {
                'bg-gray-200': isClosed,
                'bg-cardFooter': !isClosed,
            })}>
                <UserAvatar userId={userId} className={cn("my-4")}/>
                {!!user ? (
                    <div>
                        <p className="text-sm font-medium leading-none">
                            {formatName(user)}
                        </p>
                        <p className="text-sm text-muted-foreground">{user.position}</p>
                    </div>
                ) : null}
            </CaseCardFooter>
        </Card>
    )
};

export default CaseCard;
