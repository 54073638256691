import React from "react";
import HeroSection from "../components/HeroSection";
import TestimonialsSection from "../components/TestimonialsSection";
import ClientsSection from "../components/ClientsSection";
import TeamSection from "../components/TeamSection";
import SeoTags from '../components/SeoTags';
import {useAuth} from '../util/auth';
import CtaCard from '../components/CtaCard';
import {SelfAssessmentModal} from '../components/SelfAssessmentModal';
import {ScheduledClassesCarouselSection} from '../components/ScheduledClassesCarouselSection';
import {ImagePosition} from '@/components/ListCta';
import TrainingZonesStatic from '@/components/TrainingZonesStatic';
import {getHomePageProps} from "@/lib/getHomePageProps";

const getDynamicFieldValue = (field, isLoggedIn) => {
    if (!field) {
        return '';
    }
    return isLoggedIn ? field.logged_in : field.base;
}

function IndexPage({content}) {
    const auth = useAuth();

    return <>
        <SeoTags config={content.seo} />

        <div className="bg-gradient-to-b from-0% from-teal-200 to-80% to-white pt-16 gap-4 flex flex-col">
            {content.hero_1 && (
                <HeroSection
                    title={content.hero_1.title}
                    subtitle={content.hero_1.subtitle}
                    image={content.hero_1.image}
                    video={content.hero_1.video}
                    buttonPrimary={content.hero_1.button_primary ? {
                        title: getDynamicFieldValue(content.hero_1.button_primary.title, auth.isAuthenticated),
                        path: getDynamicFieldValue(content.hero_1.button_primary.path, auth.isAuthenticated),
                        helpText: getDynamicFieldValue(content.hero_1.button_primary.help_text, auth.isAuthenticated)
                    } : undefined}
                />
            )}

            {content.clients ? (
                <ClientsSection
                    className="border-t-[1px] border-gray-200 py-6"
                    title={content.clients.title}
                    items={content.clients.list}
                    anchor={content.clients.anchor}
                />
            ) : null}
        </div>

        {content.training_zones && (
            <div className="container">
                <TrainingZonesStatic
                    imagePosition={ImagePosition.Left}
                    imgWidth={330}
                    imgHeight={288}
                    imgSrc={'/media/2023/woman.svg'}
                    title={'Your tailored growth journey' || content.training_zones.title}
                    items={[
                        {
                            title: 'Personalised approach',
                            text: 'Follow a development programme tailored to your needs, shaped by self-assessment and 360 feedback.',
                        },
                        {
                            title: 'Practical leadership scenarios',
                            text: 'Engage in solving real-world leadership challenges, supported by a curated set of tools from CaseHub.',
                        },
                        {
                            title: 'Cross-cultural inspiration',
                            text: 'Experience enriching collaborative sessions with global leaders, paired with a training buddy and dedicated personal coaching.',
                        }
                    ]}
                />
            </div>
        )}

        <ScheduledClassesCarouselSection />

        {content.fitness_score_cta && (
            <CtaCard
                title="What is your level of leadership excellence?"
                text={"Take our leadership assessment to find it out and understand your development needs"}
                image={'/media/2023/faq.svg'}
                buttons={[
                    {title: 'Start here', href: '/self-assessment'},
                ]}
            />
        )}

        {content.our_team && content.our_team.visible && (
            <TeamSection
                title={content.our_team.title}
                members={content.our_team.list}
                anchor={content.our_team.anchor}
            />
        )}

        {(false && content.bottom_cta) && (
            <CtaCard
                className="bg-gray-700"
                showLogo={true}
                title="Unlock the potential of your team's skills"
                text={"Empower your team with the Tools, Expertise, and Assurance to conquer every obstacle."}
                image={'/media/2023/contacts.svg'}
                buttons={[
                    // {title: 'Explore business plans', href: '/pricing'},
                    {title: 'Ask a question', href: '/contact', variant: 'outline', environment: 'dark'},
                ]}
            />
        )}

        {content.testimonials && (
            <TestimonialsSection items={content.testimonials.list}/>
        )}

        {!auth.isAuthenticated && (
            <div className="container">
                <CtaCard
                    className="bg-teal-400 mb-24 rounded-sm"
                    title="Start for free"
                    text={"Satisfy your curiosity. Start your journey by signing up today."}
                    image={'/media/2023/process.svg'}
                    layout='reverse'
                    buttons={[
                        {title: 'Sign up', href: '/auth/signup'},
                        {title: 'Ask a question', href: '/contact', variant: 'outline', environment: 'light'},
                    ]}
                    actionsNote="Full access for 14 days. No credit card required."
                />
            </div>
        )}

        <SelfAssessmentModal />
    </>
}


export const getServerSideProps = getHomePageProps;

export default IndexPage;
