import React from 'react';
import {prepareAnchorId} from '../util/format';
import {cn} from '@/lib/utils';
import {useKeenSlider} from 'keen-slider/react';

type Client = {
    name: string;
    image: string;
}

type Props = {
    items: Client[];
    title: string;
    anchor: string;
    className?: string;
}

const animation = { duration: 20000 * 2.5, easing: (t: number) => t }

const ClientsSection = ({
    items = [],
    title,
    anchor,
    className,
}: Props) => {
    const anchorId = prepareAnchorId(anchor);
    const callbacksRefs = React.useRef<{type: string, fn: () => any}[]>([]);

    const [sliderRef] = useKeenSlider<HTMLDivElement>(
        {
            loop: true,
            mode: "free",
            slides: {
                perView: 5,
                spacing: 15,
            },
            drag: false,
            created(s) {
                const onMouseOver = () => {
                    s.animator.stop();
                }
                const onMouseOut = () => {
                    s.moveToIdx(s.track.details.abs + 5, true, animation)
                }

                callbacksRefs.current.push({ type: "mouseover", fn: onMouseOver })
                callbacksRefs.current.push({ type: "mouseout", fn: onMouseOut })

                s.container.addEventListener("mouseover", onMouseOver)
                s.container.addEventListener("mouseout", onMouseOut)
                s.moveToIdx(5, true, animation)
            },
            destroyed(s) {
                callbacksRefs.current.forEach(({ type, fn }) => {
                    s.container.removeEventListener(type, fn);
                });
                callbacksRefs.current = [];
            },
            updated(s) {
                s.moveToIdx(s.track.details.abs + 5, true, animation)
            },
            animationEnded(s) {
                s.moveToIdx(s.track.details.abs + 5, true, animation)
            },
        },
    )


    return (
        <section id={anchorId} className={cn(className)}>
            <div ref={sliderRef} className="keen-slider">
                {items.map(it => {
                    return (
                        <div className="keen-slider__slide" key={it.name + '_' + it.image}>
                            <div className="flex justify-center items-center filter grayscale hover:grayscale-0" key={it.image}>
                                <div className="hover:text-gray-900 dark:hover:text-white">
                                    <img className="h-16 w-28 object-scale-down" src={it.image} alt={it.name} />
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </section>
    )
};

export default ClientsSection;
