import React from 'react';
import Image from 'next/image';
import {cn} from '@/lib/utils';

type TrainingZonesSectionItem = {
    title: string,
    text: string,
    image: string,
    list: {
        text: string
    }[]
}

type TrainingZonesSectionProps = {
    items: TrainingZonesSectionItem[],
    className?: string,
    imgSrc: string,
    imgWidth: number,
    imgHeight: number,
    imagePosition: ImagePosition
}

export enum ImagePosition {
    Left = 'left',
    Right = 'right'
}

export const ListCta = ({
    imgSrc,
    imgWidth,
    imgHeight,
    items = [],
    className,
    imagePosition = ImagePosition.Left
}: TrainingZonesSectionProps) => {
    return (
        <div className={cn("bg-footer rounded text-footer-foreground grid lg:grid-flow-col p-6 gap-4 relative shadow-lg [&>*]:border-b-[1px] [&>*]:lg:border-b-0 [&>*:not(:last-child)]:lg:border-r-[1px] [&>*:not(:last-child)]:border-teal-300", className)}>
            {imagePosition === ImagePosition.Left && (
                <div className={cn("hidden lg:flex items-center lg:col-span-3 px-4")}>
                    <div className="relative -mt-16">
                        <Image src={imgSrc} width={imgWidth} height={imgHeight} />
                    </div>
                </div>
            )}
            {
                items.map((it) => {
                    return (
                        <div className="px-4 col-span-12 lg:col-span-3" key={it.title}>
                            <h4 className="font-bold text-xl mb-2">
                                {it.title}
                            </h4>
                            <ul className="list-disc marker:text-listMarker">
                                {it.list.map((item) => {
                                    return (
                                        <li key={item.text} className="mb-2 ml-6 font-semibold">
                                            {item.text}
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    )
                })
            }
            {imagePosition === ImagePosition.Right && (
                <div className={cn("hidden lg:flex items-center lg:col-span-3 justify-self-end")}>
                    <div className="relative -mt-16">
                        <Image src={imgSrc} width={imgWidth} height={imgHeight} />
                    </div>
                </div>
            )}
        </div>
    )
};
