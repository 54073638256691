import React from 'react';
import {Avatar, AvatarFallback, AvatarImage} from './ui/avatar';
import {Quote as QuoteIcon} from 'lucide-react';
import {Typography} from '@/components/ui/typography';

const getUserInitials = (name: string) => {
    return name.split(' ').map(it => it[0].toUpperCase()).join('');
}

type TestimonialItem = {
    title: string;
    subtitle: string;
    text: string;
    image: string;
}

type TestimonialsSectionProps = {
    items: TestimonialItem[];
}

const TestimonialsSection = (props: TestimonialsSectionProps) => {
    return (
        <div className="container my-32">
            <Typography className="text-center text-sm tracking-widest uppercase leading-relaxed font-bold">
                testimonials
            </Typography>
            <Typography variant="h2" className="text-center pb-8 border-0">
                Client’s feedback
            </Typography>
            <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4">
                {
                    props.items.map((it, idx) => {
                        const [firstSentence, restText] = splitText(it.text);
                        return (
                            <div key={it.title} className="flex flex-col shadow-lg rounded-lg border-[1px] bg-white hover:bg-teal-50 border-gray-300 p-6">
                                <QuoteIcon className="w-12 h-12 mb-3 text-teal-200 rotate-180"/>

                                <div className="flex flex-col gap-2 mb-7">
                                    <Typography variant="h3" className="text-2xl">
                                        {firstSentence}
                                    </Typography>

                                    <Typography variant="muted">
                                        {restText}
                                    </Typography>
                                </div>

                                <div className="flex items-center mt-auto gap-2">
                                    <Avatar className="w-16 h-16">
                                        <AvatarImage src={it.image} alt={it.title}/>
                                        <AvatarFallback>{getUserInitials(it.title)}</AvatarFallback>
                                    </Avatar>
                                    <div>
                                        <p className="text-sm font-medium leading-none">
                                            {it.title}
                                        </p>
                                        <p className="text-sm text-muted-foreground">{it.subtitle}</p>
                                    </div>
                                </div>

                            </div>
                        );
                    })
                }
            </div>
        </div>
    )
}

function splitText(text: string): [string, string] {
    const match = text.match(/(.*?[.!?])\s*(.*)/s);
    if (match) {
        return [match[1], match[2]];
    }
    // if no match, return the whole text as the first sentence, and an empty string as the rest
    return [text, ""];
}


export default TestimonialsSection;
