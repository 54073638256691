import React from 'react';
import dynamic from 'next/dynamic';
const ReactPlayer = dynamic(() => import('react-player/vimeo'), { ssr: false });
import {Button, buttonVariants} from './ui/button';
import Link from 'next/link';
import {cn} from '@/lib/utils';
import {Typography} from '@/components/ui/typography';
import Image from 'next/image';
import {SignUpForm} from '@/components/forms/SignUpForm';
import CodeOfConductModal from '@/components/CodeOfConductModal';
import {PlayCircle} from 'lucide-react';
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogTrigger
} from '@/components/ui/dialog';
import {RecaptchaComponent} from '@/components/Recaptcha';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import {AuthSectionType, useAuthSubmitHandlers} from '@/components/AuthSection';
import {useAuth} from '@/util/auth';

type HeroSectionProps = {
    title: string;
    subtitle: string;
    buttonPrimary: {
        title: string;
        path: string;
        helpText?: string;
    }
    image?: string;
    video?: string;
}

const HeroSection = (props: HeroSectionProps) => {
    const auth = useAuth();
    const formId = React.useId();
    const [isCodeOfConductModalOpen, setIsCodeOfConductModalOpen] = React.useState(false);
    const onSubmit = useAuthSubmitHandlers({
        afterAuthPath: '/',
        onError: (error) => {
            console.log('ERROR', error);
        },
        type: AuthSectionType.SignUp,
    });

    return (
        <div className="container">
            <section className="grid grid-cols-12 gap-4">
                <div className="col-span-12 lg:col-span-5 xl:col-span-6 flex items-center">
                    <Image src="/media/2023/team-solid.svg" width={756} height={377} alt=""/>
                </div>
                <div className="col-span-12 lg:col-span-7 xl:col-span-6 bg-white rounded-lg py-2 sm:py-6 px-2 sm:px-10 grid grid-cols-12 gap-6">
                    <div className="col-span-12 flex flex-col gap-2">
                        <Typography variant="h1">
                            Grow your skills to lead in the multicultural world!
                        </Typography>
                        <Typography variant="large">
                            Join our diverse leaders’ community for
                            collaborative learning and experience
                            sharing
                        </Typography>
                    </div>
                    <div className="col-span-12 xl:col-span-10 2xl:col-span-8 flex flex-col gap-6">
                        {!auth.isAuthenticated ? (
                            <SignUpForm onSubmit={onSubmit} formId={formId} />
                        ) : null}

                        <div className="flex gap-2">
                            {auth.isAuthenticated ? (
                                <Link passHref href="/self-assessment">
                                    <Button asChild>
                                        <a>
                                            Take assessment
                                        </a>
                                    </Button>
                                </Link>
                            ) : (
                                <Button type="submit" size="lg" form={formId} className="flex-1">Sign up</Button>
                            )}

                            {!!props.video && (
                                <Dialog>
                                    <DialogTrigger asChild>
                                        <Button type="button" size="lg" variant="outline" className="flex-1">
                                            <PlayCircle className="w-4 h-4"/>
                                            What is Casehub
                                        </Button>
                                    </DialogTrigger>
                                    <DialogContent className="w-full max-w-screen sm:max-w-screen-lg sm:w-2/3 lg:w-1/2">
                                        <DialogHeader>
                                            <DialogTitle>What is Casehub</DialogTitle>
                                        </DialogHeader>
                                        <div className="aspect-video w-full">
                                            <Video video={props.video}/>
                                        </div>
                                    </DialogContent>
                                </Dialog>
                            )}
                        </div>
                    </div>
                    {!auth.isAuthenticated ? (
                        <div className="col-span-12">
                            <Typography variant="muted" asChild>
                                <div>
                                    By signing up, you accept our
                                    {' '}
                                    <Link href="/content/terms" passHref>
                                        <a className={cn(buttonVariants({
                                            variant: 'link',
                                        }), 'p-0 h-auto')} target={'_blank'} rel={'nofollow noopener'}>Terms of use</a>
                                    </Link>
                                    {' '}
                                    and
                                    {' '}
                                    <Button
                                        variant="link"
                                        className="p-0 h-auto"
                                        onClick={() => {
                                            setIsCodeOfConductModalOpen(true)
                                        }}
                                    >
                                        Code of Conduct
                                    </Button>
                                </div>
                            </Typography>
                        </div>
                    ) : null}
                </div>
            </section>
            <CodeOfConductModal
                open={isCodeOfConductModalOpen}
                onOpenChange={setIsCodeOfConductModalOpen}
            />
        </div>
    )
};

type VideoProps = {
    video: string;
}

const Video = ({video}: VideoProps) => {
    return (
        <div className="aspect-video relative">
            <ReactPlayer
                className="absolute inset-0"
                url={`https://vimeo.com/${video}`}
                controls={true}
                width='100%'
                height='100%'
                config={{
                    playerOptions: {
                        responsive: true,
                        title: false,
                        byline: false,
                        portrait: false,
                    }
                }}
            />
        </div>
    )
}

export default RecaptchaComponent(HeroSection);
