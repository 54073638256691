import * as React from 'react';
import {cva, VariantProps} from 'class-variance-authority';
import {Slot} from '@radix-ui/react-slot';
import {cn} from '@/lib/utils';

interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
    asChild?: boolean;
}

const CaseCard = React.forwardRef<HTMLDivElement, CardProps>(
    ({ className, asChild = false, ...props }, ref) => {
        const Comp = asChild ? Slot : "div"
        return (
            <Comp
                className={cn("bg-background text-foreground shadow-lg rounded-lg border-[1px] border-card-border overflow-hidden", className)}
                ref={ref}
                {...props}
            />
        )
    }
)
CaseCard.displayName = "Card"

const CaseCardHeader = React.forwardRef<HTMLDivElement, CardProps>(
    ({ className, asChild = false, ...props }, ref) => {
        const Comp = asChild ? Slot : "div"
        return (
            <Comp
                className={cn("flex flex-col gap-2", className)}
                ref={ref}
                {...props}
            />
        )
    }
)
CaseCard.displayName = "CaseCardHeader"

const CaseCardContent = React.forwardRef<HTMLDivElement, CardProps>(
    ({ className, asChild = false, ...props }, ref) => {
        const Comp = asChild ? Slot : "div"
        return (
            <Comp
                className={cn("flex flex-col p-4", className)}
                ref={ref}
                {...props}
            />
        )
    }
)
CaseCard.displayName = "CaseCardContent"

const CaseCardIcons = React.forwardRef<HTMLDivElement, CardProps>(
    ({ className, asChild = false, ...props }, ref) => {
        const Comp = asChild ? Slot : "div"
        return (
            <Comp
                className={cn("flex w-full items-center gap-2", className)}
                ref={ref}
                {...props}
            />
        )
    }
)
CaseCard.displayName = "CaseCardIcons"

const CaseCardDescription = React.forwardRef<HTMLDivElement, CardProps>(
    ({ className, asChild = false, ...props }, ref) => {
        const Comp = asChild ? Slot : "div"
        return (
            <Comp
                className={cn("text-sm relative", className)}
                ref={ref}
                {...props}
            />
        )
    }
)
CaseCard.displayName = "CaseCardDescription"

const CaseCardActions = React.forwardRef<HTMLDivElement, CardProps>(
    ({ className, asChild = false, ...props }, ref) => {
        const Comp = asChild ? Slot : "div"
        return (
            <Comp
                className={cn("", className)}
                ref={ref}
                {...props}
            />
        )
    }
)
CaseCard.displayName = "CaseCardActions"

const CaseCardFooter = React.forwardRef<HTMLDivElement, CardProps>(
    ({ className, asChild = false, ...props }, ref) => {
        const Comp = asChild ? Slot : "div"
        return (
            <Comp
                className={cn("mt-auto", className)}
                ref={ref}
                {...props}
            />
        )
    }
)
CaseCard.displayName = "CaseCardFooter"


export { CaseCard, CaseCardHeader, CaseCardContent, CaseCardIcons, CaseCardDescription, CaseCardActions, CaseCardFooter }
