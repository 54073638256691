import React from 'react';
import {Typography} from './ui/typography';
import {Button, ButtonProps} from './ui/button';
import Link from 'next/link';
import Image from 'next/image';
import {cn} from '@/lib/utils';
import {Icons} from '@/components/icons';

type Layout = 'default' | 'reverse';

type CtaCardProps = {
    title: string;
    text?: string;
    actionsNote?: string;
    image: string;
    layout?: Layout;
    className?: string;
    showLogo?: boolean;
    buttons?: {
        title: string;
        href: string;
        variant?: ButtonProps['variant'];
        environment?: ButtonProps['environment'];
        size?: ButtonProps['size'];
    }[]
}

const CtaCard = ({
    title,
    text,
    image,
    layout = 'default',
    buttons = [],
    showLogo,
    className,
    actionsNote
}: CtaCardProps) => {
    return (
        <div className={cn('bg-teal-500', className)}>
            <div className="container">
                <div className="grid grid-cols-12 sm:p-12 gap-6">
                    <div className="col-span-12 lg:col-span-6 lg:flex items-center">
                        <Image src={image} width={756} height={413} />
                    </div>
                    <div className={cn("col-span-12 lg:col-span-6 flex flex-col items-start justify-center", {
                        'lg:order-first': layout === 'reverse'
                    })}>
                        <div className="grid grid-cols-12">
                            <div className="flex flex-col gap-2 col-span-12 2xl:col-span-8">
                                {showLogo && (
                                    <div className="flex items-center gap-2">
                                        <Icons.logo className="mr-2 h-8 w-auto mb-8 text-white" />
                                    </div>
                                )}

                                <Typography variant="h1" className="text-white">
                                    {title}
                                </Typography>
                                <Typography variant="large" className="text-white">
                                    {text}
                                </Typography>
                            </div>
                            <div className="mt-8 mb-4 col-span-12">
                                <div className="flex gap-2">
                                    {buttons.map((button, index) => (
                                        <Link key={index} href={button.href} passHref>
                                            <Button variant={button.variant} environment={button.environment}
                                                    size={button.size} asChild>
                                                <a>{button.title}</a>
                                            </Button>
                                        </Link>
                                    ))}
                                </div>
                                <Typography variant="muted" className="mt-4">
                                    {actionsNote}
                                </Typography>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CtaCard;
